<template>
    <el-dialog v-model="opened" width="80%">
        <el-row>
            <el-col :span="16">
                <div>
                    <el-upload action="/cms/v1/generate/piece" :show-file-list="false" :onSuccess="handleLocation"
                               accept="image/*" :data="{image_id: image.id}" :onProgress="handleProgress"
                               :disabled="loading" :headers="{authorization: $root.authorization}" v-if="image">
                        <el-button type="primary" :loading="loading">上传定位图</el-button>
                    </el-upload>
                </div>
                <el-table :data="data" v-loading="loading" style="margin-top: 10px" :max-height="$root.height"
                          class="draggable" row-key="id">
                    <el-table-column prop="id" width="150" label="ID"></el-table-column>
                    <el-table-column prop="x" width="80" label="X坐标"></el-table-column>
                    <el-table-column prop="y" width="80" label="Y坐标"></el-table-column>
                    <el-table-column label="原图" width="100" prop="resource">
                        <template #default="scope">
                            <el-image :src="`${$root.cdn_url}/${scope.row.resource}`" style="max-height: 88px"
                                      lazy></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="遮罩图" width="100" prop="cover">
                        <template #default="scope">
                            <el-image :src="`${$root.cdn_url}/${scope.row.cover}`" style="max-height: 88px"
                                      lazy v-if="scope.row.cover"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="预览图" width="100" prop="thumbnail">
                        <template #default="scope">
                            <div style="min-height: 88px;width: 76px" :ondrop="drop(scope.row)"
                                 :ondragover="allowDrop">
                                <el-image :src="`${$root.cdn_url}/${scope.row.thumbnail}`" style="width: 88px" lazy
                                          v-if="scope.row.thumbnail"></el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="200" label="操作">
                        <template #default="scope">
                            <el-button type="success" circle icon="Sort" class="handle" :disabled="loading"></el-button>
                            <el-button :disabled="loading" @click="openDialog(scope.row)" circle
                                       icon="Edit"></el-button>
                            <el-button type="danger" :disabled="loading" @click="remove(scope.row)" circle
                                       icon="Delete"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="8">
                <el-upload action="/cms/v1/resource" :onSuccess="handleThumbnail" accept="image/*" multiple
                           :headers="{authorization: $root.authorization}" :data="{prefix: 'thumbnail'}"
                           :show-file-list="false">
                    <el-button type="primary" :disabled="data.length === 0">批量预览图</el-button>
                </el-upload>
                <div>
                    <template v-for="item in thumbnail_list">
                        <img :src="`${$root.cdn_url}/${item}`" :id="item" style="width: 88px;" draggable="true"
                             :ondragstart="drag">
                    </template>
                </div>
            </el-col>
        </el-row>
        <template #footer>
            <el-button type="primary" :loading="loading" text @click="close" :disabled="disabledGenerateUrl">提交
            </el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="X坐标" prop="x" :rules="[{required: true, message: '请输入x坐标'}]">
                <el-input-number v-model="form.x" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="Y坐标" prop="y" :rules="[{required: true, message: '请输入y坐标'}]">
                <el-input-number v-model="form.y" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="游戏图" prop="resource" :rules="[{required: true, message: '请上传游戏图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('resource')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.resource" :src="`${$root.cdn_url}/${form.resource}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="预览图" prop="resource" :rules="[{required: true, message: '请上传预览图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('thumbnail')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.thumbnail" :src="`${$root.cdn_url}/${form.thumbnail}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../libs/utils";
import {ElMessageBox} from "element-plus";
import Sortable from "sortablejs";

export default {
    name: "Piece",
    data() {
        return {
            loading: false, opened: false, image: null, data: [], dialog_opened: false, editing: null,
            thumbnail_list: [],
            form: {
                image_id: null,
                x: null,
                y: null,
                thumbnail: null,
                resource: null,
            },
        }
    },
    methods: {
        drag(e) {
            e.dataTransfer.setData("Text", e.target.id);
        },
        allowDrop(e) {
            e.preventDefault();
        },
        drop(row) {
            return e => {
                e.preventDefault();
                if (row.thumbnail) {
                    const t = row.thumbnail;
                    this.thumbnail_list.push(t);
                }
                row.thumbnail = e.dataTransfer.getData("Text");
                this.thumbnail_list.splice(this.thumbnail_list.indexOf(row.thumbnail), 1);
                this.loading = true;
                axios.put(`/cms/v1/piece/${row.id}`, row).then(_ => {
                    this.loading = false;
                });
            };
        },
        init(image) {
            this.opened = this.loading = true;
            this.image = image;
            this.thumbnail_list = [];
            axios.get(`/cms/v1/piece`, {params: {image_id: this.image.id}}).then(res => {
                this.data = res.data.data.pieceList;
                const tbody = document.querySelector(".draggable .el-table__body tbody");
                new Sortable(tbody, {
                    handle: ".handle",
                    draggable: ".draggable .el-table__row",
                    animation: 150,
                    onEnd: ({newIndex, oldIndex}) => {
                        const current = this.data.splice(oldIndex, 1)[0];
                        this.data.splice(newIndex, 0, current);
                        this.sort();
                    }
                });
                this.loading = false;
            });
        },
        close() {
            this.loading = true;
            axios.post(`/cms/v1/generate/image`, {image_id: this.image.id}).then(res => {
                update(this.image, res.data.data);
                this.opened = this.loading = false;
            });
            this.image = null;
            this.data = [];
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/piece/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        this.form.image_id = this.image.id;
                        axios.post(`/cms/v1/piece`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.sort();
                            this.reset();
                        })
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/piece/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.sort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.sort();
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/piece`, {sort}).then(res => {
                this.loading = false;
            });
        },
        handleProgress() {
            this.loading = true;
        },
        handleSuccess(name) {
            return res => {
                this.form[name] = res.data.name;
                this.loading = false;
            }
        },
        handleLocation(res) {
            this.data = res.data.pieceList;
            this.loading = false;
        },
        handleThumbnail(res) {
            if (!this.thumbnail_list.includes(res.data.name)) {
                this.thumbnail_list.push(res.data.name);
            }
        },
    },
    computed: {
        disabledGenerateUrl() {
            let valid = false;
            this.data.forEach(d => {
                if (!d.thumbnail) {
                    valid = true;
                }
            });
            return valid;
        }
    },
}
</script>

<style scoped>

</style>